<template>
    <base-page>
        <titlu-pagina Titlu="Harti" @on_add_clicked="show_dialog()" :AdaugaVisible='true' />
        <el-table :data="Results" >
            <el-table-column prop='Nume' label='Nume'></el-table-column>
            <el-table-column prop='Link' label='Link'></el-table-column>
            <el-table-column fixed="right" label="Actiuni" width="200px" >
                <template slot-scope="scope" >

                    <el-tooltip content="Modificare">
                        <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row)" />
                    </el-tooltip>

                    <el-tooltip content="Sterge" >
                        <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                    </el-tooltip>

                </template>
            </el-table-column>
        </el-table>
        <Harti-dialog ref='dlg' @save="refresh_info()" />
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Harti_dialog from '@/pages/harti/Harti_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "harti",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Harti-dialog': Harti_dialog,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: [],
            base_url: ''
        }
    },
    methods: {

        async refresh_info(){
            let response        = await this.post("harti");
            this.Results        = response.Results;
        },
        reset(){
            this.refresh_info();
        },

        async delete_item( item ){
            var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
            if( confirm )
            {
                await this.post("harti/delete_item", { id: item.Id } );
                this.refresh_info();
            }
        },

        show_dialog(item){
            this.$refs.dlg.show_me(item);
            this.refresh_info();
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.refresh_info();
    }
};
</script>